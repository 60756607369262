<app-popup [title]="'account.login.login.title' | translate">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-form-input class="tw-mb-4" controlName="email" [label]="'account.login.form.login' | translate" />

        <app-form-input class="tw-mb-4" controlName="password" type="password" [preview]="true" [label]="'account.login.form.password' | translate" />

        <div class="sm:tw-mb-9 tw-mb-8">
            <a class="link-reset-password" [routerLink]="['..', 'reset-password-request']">
                {{ 'account.login.form.forgot-password-2' | translate }}
                <app-icon icon="chevron-right" [size]="20" color="gray-600" />
            </a>
        </div>

        <div class="sm:tw-mb-9 tw-mb-8 info">
            <div class="tw-flex tw-items-center tw-gap-x-2 tw-mb-3">
                <app-icon icon="lightbulb" color="primary" [size]="20" />
                <h6 class="tw-text-base tw-font-medium">{{ 'account.login.multidevice.info-1' | translate }}</h6>
            </div>
            <div class="tw-text-sm tw-font-normal">{{ 'account.login.multidevice.info-2' | translate }}</div>
        </div>

        <div class="tw-flex tw-justify-center sm:tw-mb-10 tw-mb-8">
            <button
                app-button
                type="submit"
                size="md"
                [content]="'account.login.login.title' | translate"
                [spinner]="spinner$ | async"
                [disabled]="spinner$ | async"
            ></button>
        </div>

        <div class="tw-text-center">
            <app-button-link
                [text]="'account.login.form.not-registered' | translate"
                [linkText]="'account.login.form.register-now' | translate"
                [link]="['..', 'register']"
            />
        </div>
    </form>
</app-popup>
