import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { filter, Subscription, tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AccountFacade } from '@app/store/account';
import { ButtonComponent, IconComponent, PopupComponent } from '@app/v2/shared/components';
import { FormInputComponent, FormValidators } from '@app/v2/shared/forms';

@Component({
    selector: 'app-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, ReactiveFormsModule, TranslateModule, FormInputComponent, ButtonComponent, IconComponent, PopupComponent],
})
export class SetPasswordComponent implements OnInit, OnDestroy {
    private readonly accountFacade = inject(AccountFacade);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly formBuilder = inject(NonNullableFormBuilder);
    private readonly router = inject(Router);

    private readonly subscriptions$ = new Subscription();

    readonly spinner$ = this.accountFacade.saving$;

    readonly form = this.formBuilder.group({
        password: this.formBuilder.control<string>('', [FormValidators.Required, FormValidators.Text]),
    });

    ngOnInit(): void {
        this.subscriptions$.add(
            this.accountFacade.saving$
                .pipe(
                    filter((saving) => saving === false),
                    tap(() => this.form.enable()),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.accountFacade.resetPassword$
                .pipe(
                    filter((resetPassword) => resetPassword === true),
                    tap(() => this.close()),
                )
                .subscribe(),
        );

        this.addErrorSubscription();
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onSubmit() {
        this.form.markAllAsTouched();
        const token = <string>this.activatedRoute.snapshot.paramMap.get('token');

        if (this.form.valid) {
            this.form.disable();
            const value = this.form.getRawValue();
            this.accountFacade.resetPassword(value.password, token);
        }
    }

    private addErrorSubscription() {
        this.subscriptions$.add(
            this.accountFacade.errors$
                .pipe(
                    tap((errors) => {
                        Object.keys(errors).forEach((key) => {
                            this.form.controls.password.setErrors({
                                password: errors[key],
                            });
                        });
                    }),
                )
                .subscribe(),
        );
    }

    private close() {
        this.router.navigate(['../../..'], { relativeTo: this.activatedRoute });
    }
}
