<app-popup [title]="'account.request-reset-password.title' | translate">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-form-input class="tw-mb-4" controlName="email" [label]="'account.login.form.login' | translate" />

        <div class="info tw-mb-9">{{ 'account.request-reset-password.description' | translate }}</div>

        <div class="tw-flex tw-justify-center">
            <button
                app-button
                type="submit"
                size="md"
                [content]="'btn.request-password-reset' | translate"
                [spinner]="spinner$ | async"
                [disabled]="spinner$ | async"
            ></button>
        </div>
    </form>
</app-popup>
