import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NonNullableFormBuilder, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BehaviorSubject, filter, Subscription, tap } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { AccountFacade } from '@app/store/account';
import { ButtonComponent, ButtonLinkComponent, IconComponent, PopupComponent } from '@app/v2/shared/components';
import { FormCheckboxComponent, FormInputComponent, FormValidators } from '@app/v2/shared/forms';
import { PrivacyPolicyDirective, RegulationsDirective } from '@app/v2/shared/directives';

@Component({
    selector: 'app-register-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        TranslateModule,
        FormInputComponent,
        FormCheckboxComponent,
        ButtonComponent,
        ButtonLinkComponent,
        IconComponent,
        RegulationsDirective,
        PrivacyPolicyDirective,
        PopupComponent,
    ],
})
export class FormComponent implements OnInit, OnDestroy {
    private readonly accountFacade = inject(AccountFacade);
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly formBuilder = inject(NonNullableFormBuilder);
    private readonly router = inject(Router);

    private readonly subscriptions$ = new Subscription();

    readonly spinner$ = this.accountFacade.saving$;

    readonly option1Expand$ = new BehaviorSubject(false);
    readonly option2Expand$ = new BehaviorSubject(false);

    readonly form = this.formBuilder.group({
        email: this.formBuilder.control<string>('', [FormValidators.Required, FormValidators.Email]),
        password: this.formBuilder.control<string>('', [FormValidators.Required, FormValidators.Text]),
        consent: this.formBuilder.group({
            marketing: this.formBuilder.control<boolean>(false),
            telemarketing: this.formBuilder.control<boolean>(false),
            termsOfService: this.formBuilder.control<boolean>(false, [FormValidators.RequiredTrue]),
        }),
    });

    ngOnInit(): void {
        this.subscriptions$.add(
            this.accountFacade.saving$
                .pipe(
                    filter((saving) => saving === false),
                    tap(() => this.form.enable()),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.accountFacade.registered$
                .pipe(
                    filter((registered) => registered === true),
                    tap(() => this.redirect()),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.accountFacade.errors$
                .pipe(
                    tap((errors) => {
                        Object.keys(errors).forEach((key) => {
                            this.form.get(key)?.setErrors({
                                customs: errors[key],
                            });
                        });
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onSubmit() {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            this.form.disable();
            const value = this.form.getRawValue();
            this.accountFacade.register(value.email, value.password, null, value.consent);
        }
    }

    toggleExpand1Click(event: MouseEvent) {
        event.preventDefault();
        this.option1Expand$.next(!this.option1Expand$.getValue());
    }

    toggleExpand2Click(event: MouseEvent) {
        event.preventDefault();
        this.option2Expand$.next(!this.option2Expand$.getValue());
    }

    private redirect() {
        this.router.navigate(['.', 'success', this.form.controls.email.getRawValue()], { relativeTo: this.activatedRoute, replaceUrl: true });
    }
}
